<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="id"
    show-expand
    sort-by="id"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Surveyors Attendances List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- BOM Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []"
                          >Clear</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      placeholder="Select Surveyor"
                      :items="surveyorList"
                      item-text="name"
                      item-value="id"
                      label="Surveyor"
                      v-model="filterSurveyor"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      :items="['All', 'Yes', 'No']"
                      label="Aproval"
                      v-model="filterAproval"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()"
                >Clear Filters</v-btn
              >
              <v-btn color="blue darken-1" text @click="dialogFilters = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="filterItems()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- EOM Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="950px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="formNewItem()"
              >New Surveyors Attendance</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                      @change="getWOData(current_wo_id)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="folder"
                      v-model="current_wo_vessel"
                      label="Vessel"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="folder"
                      v-model="current_wo_voyage"
                      label="Voyage"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <!--Levanta los nominations segun el WO elegido previamente. Hay algunos WO que no tienen surveyor_nomination. En ese caso desactivamos el campo.-->
                    <v-autocomplete
                      prepend-icon="article"
                      placeholder="Select Surveyor Nomination"
                      :items="woNominationsList"
                      item-text="nr"
                      item-value="nr"
                      label="Surveyor Nomination Nr."
                      v-model="editedItem.nomination"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      placeholder="Select Surveyor"
                      :items="surveyorList"
                      item-text="name"
                      item-value="id"
                      label="Surveyor"
                      v-model="editedItem.surveyor"
                      :search-input.sync="editedItem.surveyor_label"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-switch
                      v-model="editedItem.aproval"
                      label="Aproval"
                      color="info"
                      hide-details
                      class="mt-0"
                      :disabled="disableApproveAction"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!--<SurveyorsAttendanceExpensesList
                      :headers="headersExpenses"
                      :items="itemsExpensesNew"
                      :data_table_top="true"
                      :data_table_footer="true"
                      @initialize="initialize"
                    ></SurveyorsAttendanceExpensesList>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--  Print -->
        <v-dialog v-model="dialog4" max-width="1100px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <div
                      class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
                    >
                      <div class="kt-portlet">
                        <div class="kt-portlet__body kt-portlet__body--fit">
                          <div class="kt-invoice-2">
                            <div class="kt-invoice__head">
                              <div class="kt-invoice__container">
                                <div class="kt-invoice__brand">
                                  <div
                                    href="#"
                                    class="kt-invoice__logo text-left"
                                  >
                                    <a href="#">
                                      <img
                                        src="assets/media/logos/logo-dark.png"
                                      />
                                    </a>
                                    <span
                                      class="kt-invoice__desc text-left mt-6"
                                    >
                                      <h4>Surveyor´s Attendance</h4>
                                      <span>
                                        N°: OC/010306 <br />
                                        DATE: 16/12/2019 <br />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div class="kt-invoice__items">
                                  <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle"
                                      >OUR REFERENCE:</span
                                    >
                                    <span class="kt-invoice__text">35260</span>
                                  </div>
                                  <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle"
                                      >SURVEYOR:</span
                                    >
                                    <span class="kt-invoice__text"
                                      >Oscar Cherro</span
                                    >
                                  </div>
                                  <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle"
                                      >PORTS:</span
                                    >
                                    <span class="kt-invoice__text"
                                      >Campana</span
                                    >
                                  </div>
                                  <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle"
                                      >ACTIVITIES:</span
                                    >
                                    <span class="kt-invoice__text"
                                      >Surveys</span
                                    >
                                  </div>
                                  <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle"
                                      >VESSEL:</span
                                    >
                                    <span class="kt-invoice__text"
                                      >Nuar B/ Besiktas Zealand (WP)</span
                                    >
                                  </div>
                                </div>
                                <div class="kt-invoice__desc text-left mt-10">
                                  <span
                                    >BREAKDOWN FROM: 07/10/2019 - TO:
                                    08/11/2019</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="kt-invoice__body">
                              <div class="kt-invoice__container">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>DESCRIPTION</th>
                                        <th>DATE</th>
                                        <th>PLACE / PORT</th>
                                        <th>TIME IN</th>
                                        <th>TIME OUT</th>
                                        <th>TYPE</th>
                                        <th>DAILY / HOURLY QT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          Acknowledge instructions. Made survey
                                          arrangements.
                                        </td>
                                        <td>07/10/2019</td>
                                        <td>Buenos AIres</td>
                                        <td>17:10</td>
                                        <td>18:00</td>
                                        <td>Office</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          1
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Travelling to attendance location.
                                          Waiting time for gate clearance
                                        </td>
                                        <td>09/10/2019</td>
                                        <td>Campana</td>
                                        <td>10:00</td>
                                        <td>13:00</td>
                                        <td>Travelling</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          3
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Survey on board</td>
                                        <td>09/10/2019</td>
                                        <td>Campana</td>
                                        <td>13:00</td>
                                        <td>18:00</td>
                                        <td>Inspection</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          5
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Travelling from attendance location
                                        </td>
                                        <td>10/10/2019</td>
                                        <td>Campana</td>
                                        <td>18:00</td>
                                        <td>20:00</td>
                                        <td>Travelling</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          2
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Cause Consideration analysis. Analysis
                                          and sketch of vessels drafts and
                                          previous damages
                                        </td>
                                        <td>10/10/2019</td>
                                        <td>Buenos Aires</td>
                                        <td>07:00</td>
                                        <td>11:00</td>
                                        <td>Office</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          3
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Reviewing case. Replying to Club's
                                          enquiry
                                        </td>
                                        <td>10/10/2019</td>
                                        <td>Buenos Aires</td>
                                        <td>13:30</td>
                                        <td>14:30</td>
                                        <td>Office</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          1
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Travelling from attendance location
                                        </td>
                                        <td>08/11/2019</td>
                                        <td>Campana</td>
                                        <td>18:00</td>
                                        <td>20:00</td>
                                        <td>Travelling</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          4
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="table-responsive mt-10">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>EXPENSES</th>
                                        <th>DESCRIPTION</th>
                                        <th>TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          Milleage to/from Campana Port, 78 km
                                          each
                                        </td>
                                        <td>Mileage</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          117
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Road Tolls</td>
                                        <td>Tolls</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          2.32
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Meals</td>
                                        <td>Meals</td>
                                        <td class="kt-font-danger kt-font-lg">
                                          5.57
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="kt-invoice__container">
                              <div class="row py-10">
                                <div class="col-md-3">
                                  <img src="assets/media/logos/logo-dark.png" />
                                </div>
                                <div class="col-md-3">
                                  Mendoza 4002 <br />
                                  Postal Code 1430BRT <br />
                                  Ciudad Autonoma de Buenos Aires <br />
                                  República Argentina
                                </div>
                                <div class="col-md-3">
                                  Telephone: (5411) 4542-9156 <br />
                                  Telephone: (5411) 4545-7501 <br />
                                  Telephone: (5411) 4544-3847 <br />
                                  companymail@simonsen.com.ar <br />
                                  www.simonsen.com.ar
                                </div>
                                <div class="col-md-3">
                                  VAT Reg. N°: 30-57019464-6 <br />
                                  Ing. Brutos: 477239-03 <br />
                                  D.N.R.P.: 57019464
                                </div>
                              </div>
                            </div>
                            <div class="kt-invoice__actions">
                              <div class="kt-invoice__container">
                                <button
                                  type="button"
                                  class="btn btn-label-brand btn-bold"
                                  onclick="window.print();"
                                >
                                  Download Invoice
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-brand btn-bold"
                                  onclick="window.print();"
                                >
                                  Print Invoice
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!--
                  <v-btn color="blue darken-1" text @click="close"
                  >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save"
                  >Save</v-btn
                  >
              -->
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Print -->
      </v-toolbar>
    </template>

    <template v-slot:item.aproval="{ item }">
      <v-chip color="green" dark v-if="item.aproval == 1">yes</v-chip>
      <v-chip color="red" dark v-else>no</v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="seeItem(item)"
        v-b-tooltip.hover
        title="Print"
        >mdi-printer</v-icon
      >
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
        >mdi-pencil</v-icon
      >
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row">
              <SurveyorsAttendanceTaskList
                :headers="headers_extra"
                :items="items_extra"
                :search="search"
                :hide-default-footer="true"
                :current_parent_id="item.id"
                item-key="id"
                sort-by="id"
                class="elevation-1 transparent-table mb-6 w-100"
              ></SurveyorsAttendanceTaskList>
              <!--
              Este listado de Expenses tiene que traer / crear unicamente los de este Surveyors_Attendance. 
              Hay que definir si este listado queda, agregarlo en el form de la creación de nuevo 
            -->
              <!--<SurveyorsAttendanceExpensesList
              :headers="headersExpenses"
              :items="itemsExpenses"
              :data_table_top="true"
              :data_table_footer="true"
              @initialize="initialize"
            ></SurveyorsAttendanceExpensesList>-->
            </div>
          </div>
        </div>
      </td>
    </template>
    <template slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";
//import SurveyorsAttendanceExpensesList from "@/views/pages/surveyors-attendance/SurveyorsAttendanceExpensesList.vue";
import SurveyorsAttendanceTaskList from "@/views/pages/surveyors-attendance/SurveyorsAttendanceTaskList";
import { mapGetters } from "vuex";

export default {
  components: {
    //SurveyorsAttendanceExpensesList,
    SurveyorsAttendanceTaskList,
  },

  data: () => ({
    uri: "surveyorattlist",
    woNominationsList: [],
    workOrdersList: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    editedIndex: -1,
    editedIndex2: -1,
    editedItem: {
      work_order: null,
      id: "",
      date: new Date().toISOString().substr(0, 10),
      vessel: "",
      aproval: false,
      voyage: "",
      description: "",
      surveyor: "",
      surveyor_label: "",
    },
    defaultItem: {
      work_order: null,
      id: "",
      date: new Date().toISOString().substr(0, 10),
      vessel: "",
      aproval: false,
      voyage: "",
      description: "",
      surveyor: "",
      surveyor_label: "",
    },
    switch1: true,
    surveyorList: [],

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Number", value: 0 },
      { text: "Work Order", value: 1 },
      { text: "Nomination", value: 2 },
      { text: "Vessel", value: 3 },
      { text: "Voyage", value: 4 },
      { text: "Surveyor", value: 5 },
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    filterAproval: "",
    filterSurveyor: "",
    filterWorkOrder: "",
    disableApproveAction: true,

    /*headersExpenses: [
      { text: "Description", value: "description" },
      { text: "Total", value: "total" },
      { text: "Actions", value: "actions" }
    ],*/

    //itemsExpenses: [],

    //itemsExpensesNew: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Surveyors Attendance"
        : "Edit Surveyors Attendance";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "New Task" : "Edit Task";
    },

    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters(["currentUser"]),
  },

  props: [
    "headers",
    "headers_extra",
    "items",
    "items_extra",
    "items_extra_new",
    "search_tool",
    "current_wo_id",
    "current_wo_vessel",
    "current_wo_voyage",
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    getWOData(woId) {
      var _self = this;
      genericService.getRecord("workorders", woId, function (record) {
        _self.current_wo_voyage = record.voyage;
        _self.current_wo_vessel = record.vessel;
      });

      genericService.getRelatedWORecordList(
        "/workorders",
        woId,
        "nominations",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.woNominationsList = records;
        }
      );
    },

    seeItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      //window.open("http://localhost/simonsen_api/reports/pdf/surveyors_attendance/" +item.id +"/","_blank");
      //window.open('http://66.97.33.159/api/reports/pdf/surveyors_attendance/'+item.id+'/', '_blank');
      window.open('http://crm.simonsen.com.ar/api/reports/pdf/surveyors_attendance/'+item.id+'/', '_blank');
    },

    editItem(item, dialog) {
      this.getWOData(item.work_order);
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.formNewtItem = false;
        this.dialog = true;
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      } else if (dialog == "dialog2") {
        this.dialog2 = true;
        this.editedIndex2 = this.items_extra.indexOf(item);
        this.editedItem = Object.assign({}, item);
      } else if (dialog == "dialog3") {
        this.dialog3 = true;
        this.editedIndex2 = this.items_extra_new.indexOf(item);
        this.editedItem = Object.assign({}, item);
      }
    },

    deleteItem(item, list) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          });
        }
      });
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    close2() {
      /* Listado de Dialogs */
      this.dialog3 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedIndex2 = -1;
      }, 300);
    },

    save(items) {
      var _self = this;

      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord(
          "/" + this.uri + "/" + this.editedItem.id,
          this.editedItem,
          function () {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        );
      } else {
        this.editedItem.work_order = this.current_wo_id;
        this.editedItem.vessel = this.current_wo_vessel;
        this.editedItem.voyage = this.current_wo_voyage;

        items.push(this.editedItem);

        genericService.createRecord(
          "/" + this.uri,
          this.editedItem,
          function (rta) {
            if (rta != undefined) {
              Swal.fire({
                title: "",
                text: "Record has been successfully saved!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              _self.editedItem.id = rta.id;
            }
          }
        );
      }
      this.currentItems(items);
      this.close();
    },

    save2(item) {
      if (this.editedIndex2 > -1) {
        Object.assign(item[this.editedIndex2], this.editedItem);
      } else {
        item.push(this.editedItem);
      }
      this.close();
    },

    save3(item) {
      if (this.editedIndex2 > -1) {
        Object.assign(item[this.editedIndex2], this.editedItem);
      } else {
        item.push(this.editedItem);
      }
      this.close2();
    },

    reset() {
      this.$emit("initialize");
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },

    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      _self.filterSurveyor = "";
      _self.filterAproval = "";

      genericService.getRecordListWithLimit(
        "/" + this.uri,
        10,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.items = records;
          _self.dialogFilters = false;
        }
      );
    },

    filterItems() {
      var _self = this;
      var params = {
        module: _self.uri,
        range: _self.rangeDates,
        wo: _self.filterWorkOrder,
        surveyor: _self.filterSurveyor,
        aproval: _self.filterAproval,
      };

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function (records) {
        _self.items = records;
        _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */

    formNewItem() {
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200);
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    var _self = this;

    if (this.search_tool == true) {
      this.focusField(this.$refs.search);
    }

    genericService.getRecordList(
      "/list/users/Surveyor/Surveyor",
      function (records) {
        _self.surveyorList = records;
      }
    );

    genericService.getRecordList("/workorders/", function (records) {
      _self.workOrdersList = records.reverse();
    });

    _self.getWOData(this.current_wo_id);

    if(_self.currentUser.role != 'Surveyor') {
      _self.disableApproveAction = false;
    }
  },
};
</script>

<style lang="scss">
.listados-surveyors .v-application--wrap {
  min-height: auto !important;
}
.listados-surveyors #app,
.listados-surveyors .theme--light.v-data-table {
  background-color: #f2f3f8 !important;
}
</style>
