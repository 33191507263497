var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.taskItems,"item-key":"id","sort-by":"date","sort-desc":"","loading":_vm.loading,"loading-text":"Loading... Please wait","single-expand":true,"expanded":_vm.expanded,"show-expand":"","hide-default-footer":true,"disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event},"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Tasks")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"gray"}},on),[_vm._v("New Task")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Date","prepend-icon":"event","readonly":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}},on))]}}]),model:{value:(_vm.popDatepick1),callback:function ($$v) {_vm.popDatepick1=$$v},expression:"popDatepick1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.popDatepick1 = false}},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":[
                        'Travel',
                        'Office',
                        'Inspection'
                        ],"label":"Type"},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"autocomplete":"Description","label":"Description","rows":"6","required":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"autocomplete":"Place","label":"place","rows":"3","required":""},model:{value:(_vm.editedItem.place),callback:function ($$v) {_vm.$set(_vm.editedItem, "place", $$v)},expression:"editedItem.place"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":[
                        'Daily',
                        'Hourly'
                        ],"label":"Time Frame"},model:{value:(_vm.editedItem.time),callback:function ($$v) {_vm.$set(_vm.editedItem, "time", $$v)},expression:"editedItem.time"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Amount"},model:{value:(_vm.editedItem.amount),callback:function ($$v) {_vm.$set(_vm.editedItem, "amount", $$v)},expression:"editedItem.amount"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save(_vm.items)}}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"small":"","title":"Edit"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"small":"","title":"Delete"},on:{"click":function($event){return _vm.deleteItem(item, _vm.items_extra)}}},[_vm._v(" mdi-delete ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }