<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <SurveyorsAttendanceList
            :headers="headersSurveyorsAttendance"
            :headers_extra="headersSurveyorsAttendanceExtras"
            :items="itemsSurveyorsAttendance"
            :items_extra="itemsSurveyorsAttendanceExtras"
            :items_extra_new="itemsSurveyorsAttendanceExtrasNew"
            @initialize="initialize"
            :search_tool="true"
          ></SurveyorsAttendanceList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import SurveyorsAttendanceList from "@/views/pages/surveyors-attendance/SurveyorsAttendanceList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    SurveyorsAttendanceList,
  },

  data: () => ({
    uri: "surveyorattlist",
    headersSurveyorsAttendance: [
      { text: "ID", value: "id", sortable: true, filterable: true },
      { text: "Work Order", value: "work_order", sortable: false },
      { text: "Nomination", value: "nomination", sortable: false },
      { text: "Vessel", value: "vessel", sortable: false },
      { text: "Voyage", value: "voyage", sortable: false },
      { text: "Surveyor", value: "surveyor_label", sortable: false },
      { text: "Aproval", value: "aproval", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersSurveyorsAttendanceExtras: [
      { text: "ID", value: "id", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "Timeframe", value: "time", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsSurveyorsAttendance: [],
    itemsSurveyorsAttendanceExtras: [],
    itemsSurveyorsAttendanceExtrasNew: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsSurveyorsAttendance = records;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surveyor´s Attendance" }]);
  },
  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
