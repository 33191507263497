<template>
  <v-data-table
    :headers="headers"
    :items="taskItems"
    item-key="id"
    sort-by="date"
    sort-desc
    class="elevation-1"
    :loading="loading"
    loading-text="Loading... Please wait"
    :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    @current-items="currentItems"
    :hide-default-footer="true"
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Tasks</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="gray" class="mb-2" v-on="on"
              >New Task</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      v-model="editedItem.type"
                      :items="[
                          'Travel',
                          'Office',
                          'Inspection'
                          ]"
                      label="Type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                        autocomplete="Description"
                        label="Description"
                        rows=6
                        required
                        v-model="editedItem.description"
                      ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                        autocomplete="Place"
                        label="place"
                        rows=3
                        required
                        v-model="editedItem.place"
                      ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      v-model="editedItem.time"
                      :items="[
                          'Daily',
                          'Hourly'
                          ]"
                      label="Time Frame"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.amount"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        v-b-tooltip.hover
        title="Edit"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item, items_extra)"
        v-b-tooltip.hover
        title="Delete"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"surveyoratttasklist",
    taskItems: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    editedIndex: -1,
    editedIndex2: -1,
    editedItem: {
      id: "",
      parent_type: "",
      parent_id: "",
      date: new Date().toISOString().substr(0, 10),
      type: "",
      description: "",
      place: "",
      time: "",
      amount: ""
    },
    defaultItem: {
      id: "",
      parent_type: "",
      parent_id: "",
      date: new Date().toISOString().substr(0, 10),
      type: "",
      description: "",
      place: "",
      time: "",
      amount: ""
    },
    switch1: true,
    surveyorList:[],

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Number", value: 0 },
      { text: "Work Order", value: 1 },
      { text: "Nomination", value: 2 },
      { text: "Vessel", value: 3 },
      { text: "Voyage", value: 4 },
      { text: "Surveyor", value: 5 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,

    /*headersExpenses: [
      { text: "Description", value: "description" },
      { text: "Total", value: "total" },
      { text: "Actions", value: "actions" }
    ],*/

    //itemsExpenses: [],

    //itemsExpensesNew: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Task"
        : "Edit Task";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    }
  },

  props: [
    "headers", 
    "headers_extra", 
    "items", 
    "items_extra", 
    "items_extra_new", 
    "search_tool", 
    "current_parent_id"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    
    editItem(item) {
      this.formNewtItem = false;
      this.dialog = true;
      this.editedIndex = this.taskItems.indexOf(item);
      this.editedItem = item;
    },

    deleteItem(item) {
      
      var list = this.taskItems;

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },


    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    
    save(items) {
      var _self = this;
      
      if (_self.editedIndex > -1) {
        items[_self.editedIndex] = _self.editedItem;
        genericService.updateRecord("/"+_self.uri+"/" + _self.editedItem.id, _self.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        _self.editedItem.parent_id = _self.current_parent_id;
        _self.editedItem.parent_type = "surveyors_attendance";
        _self.taskItems.push(_self.editedItem);
        genericService.createRecord("/"+_self.uri, _self.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
        });

      }
      this.close();
    },


    reset() {
      //this.$emit("initialize");
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },

    filterItems(itemFiltered) {
      if (itemFiltered == 99 || itemFiltered == null) {
        this.headers.forEach(header => (header.filterable = true));
      } else {
        this.headers.forEach(header => (header.filterable = false));
        this.headers[itemFiltered].filterable = true;
      }
      this.dialogFilters = false;
      return this.headers;
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },
    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    }
  },

  created() {
    var _self = this;
    genericService.getRelatedList(_self.uri, 'surveyors_attendance', _self.current_parent_id, function(records){
      _self.taskItems = records;
    });
  },

  mounted() {
    if(this.search_tool == true){
      this.focusField(this.$refs.search);
    }

  }
};
</script>

<style lang="scss">
  .listados-surveyors .v-application--wrap{
    min-height: auto !important;
  }
  .listados-surveyors #app,
  .listados-surveyors .theme--light.v-data-table{
    background-color: #F2F3F8 !important;
  }
</style>
